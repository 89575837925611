const language = state => state.language

const languages = state => state.languages

const locale = state => state.locale

const apiUrl = state => state.apiUrl

const appBaseUrl = state => state.appBaseUrl

const token = state => state.token

const apiHeader = state => (
  {
    headers:
    {
      Authorization: `Bearer ${state.token}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin,Content-Type,X-Requested-With,Accept,Authorization'
    }
  }
)

const pageInfo = state => state.pageInfo

const categoryinfo = state => state.categoryinfo

const kitchenInfo = state => state.kitchenInfo

const categorieslist = state => state.categorieslist

const categoryId = state => state.categoryId

const kitchenId = state => state.kitchenId

const kitchenSlug = state => state.kitchenSlug

const categorySlug = state => state.categorySlug

const stepInfo = state => state.stepInfo

const stepHistory = state => state.stepHistory

const chosenFeatures = state => state.chosenFeatures

const addOnFeatures = state => state.addOnFeatures

const chosenDesignFeatures = state => state.chosenDesignFeatures

const doneCategories = state => state.doneCategories

const addedProduct = state => state.addedProduct

const addedProducts = state => state.addedProducts

const stepCounter = state => state.stepCounter

const sizeRestrictions = state => state.sizeRestrictions

const chosenFeaturesObject = state => state.chosenFeaturesObject

const temporaryChosenFeatures = state => state.temporaryChosenFeatures

const returnUrl = state => state.returnUrl

export default {
  language,
  languages,
  locale,
  token,
  apiUrl,
  apiHeader,
  pageInfo,
  categoryinfo,
  categorySlug,
  categorieslist,
  categoryId,
  kitchenId,
  kitchenSlug,
  kitchenInfo,
  stepInfo,
  stepHistory,
  chosenFeatures,
  chosenDesignFeatures,
  chosenFeaturesObject,
  temporaryChosenFeatures,
  doneCategories,
  addedProduct,
  addedProducts,
  stepCounter,
  sizeRestrictions,
  addOnFeatures,
  appBaseUrl,
  returnUrl
}
