<template>
  <svg
    class="c-icon"
    :class="[
      placement === 'before' ? 'c-icon--before' : placement === 'after'
        ? 'c-icon--after' : '',
      size === '12' ? 'c-icon--small' : size === '24'
        ? 'c-icon--large' : size === '40'
        ? 'c-icon--xl' : size === '96'
        ? 'c-icon--xxl' : ''
      ]">
    <use :xlink:href="`${spritePath}#icon-${type}`"></use>
  </svg>
</template>
<script>
export default {
  name: 'Icon',
  props: {
    type: String,
    placement: String,
    size: String,
    placementValidator: (placement) => ['before', 'after'].includes(placement),
    sizeValidator: (size) => ['12', '16', '24', '40', '96'].includes(size)
  },
  data () {
    return {
      spritePath: require('@/assets/images/sprite.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &--small {
    width: 12px;
    height: 12px;
  }

  &--large {
    width: 24px;
    height: 24px;
    @include mq($screen-m, max) {
      width: 16px;
      height: 16px;
    }
  }

  &--xl {
    width: 40px;
    height: 40px;
  }

  &--xxl {
    width: 96px;
    height: 96px;
  }

  &--before {
    margin-right: 8px;
  }

  &--after {
    margin-left: 8px;
  }
}
</style>
