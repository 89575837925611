<template>
  <transition name="fade">
    <div
      v-if="showModal"
      class="fixed left-4 right-4 bottom-4 max-w-md bg-brand-deep-blue-5 rounded-sm py-4 z-50">
      <div class="sm:flex-1 px-4 md:px-6 text-sm">
        {{ translations[locale].text.cookies_text }}
      </div>

      <div class="flex px-4 md:px-6 mt-4 gap-3">
        <v-button variation="primary" class="w-1/2" style="min-width: 0;" @click="accept">
          {{ translations[locale].text.accept }}
        </v-button>

        <v-button variation="secondary" class="w-1/2" style="min-width: 0;" @click="decline">
          {{ translations[locale].text.decline }}
        </v-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import Cookie from '@/cookie'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import { mapActions } from 'vuex'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'CookiePopup',
  data () {
    return {
      locale: this.$route.params.lang,
      translations
    }
  },
  components: {
    'v-button': Button
  },
  computed: {
    showModal () {
      return this.$store.state.showCookieConsent
    }
  },
  mounted () {
    if (!Cookie.get('cookies-consent')) {
      this.toggleCookieConsent(true)
      this.setCookieConsent(false)
    }
  },
  methods: {
    ...mapActions(['setCookieConsent', 'toggleCookieConsent']),
    accept () {
      Cookie.set('cookies-consent', 'yes', 180)
      this.setCookieConsent(true)
      this.toggleCookieConsent(false)
    },
    decline () {
      Cookie.set('cookies-consent', 'no', 180)
      this.toggleCookieConsent(false)
      this.setCookieConsent(false)
    }
  }
}
</script>
