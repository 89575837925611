<template>
  <div class="l-container">
    <v-stepbuttons/>
    <div class="c-step" v-if="stepInfo">
      <!-- Step head -->
      <v-stephead
        :stepCounter="stepCounter"
        :title="stepInfo.title"
        :text="stepInfo.text"
        :addedProduct="addedProduct ? addedProduct : []"
      />
      <!-- Step head end -->

      <!-- Step content start -->
      <div class="c-step__child-steps" v-if="!stepInfo.is_multiselect && !stepInfo.show_result">
        <div class="c-step__child-step" v-for="(child, index) in sortedChildren" :key="child">
          <div v-if="child.is_multiselect" class="c-step__child-step--multiselect">
            <v-choosable-feature
              v-for="(feature, index) in child.features"
              :key="feature"
              :featureId="feature.id"
              imgPosition="right"
              :title="feature.title"
              :checkboxLabel="feature.checkbox_title ? feature.checkbox_title : 'Translation missing'"
              :content="feature.text"
              :number="filterFeaturesBasedOnSize(sizeRestrictions, getUniqueProductSizes(feature.products, feature.title)) ? index : index + 1"
              :images="feature.media && feature.media.length ? Object.values(feature.media) : []"
              :checked="chosenFeatures.includes(feature.id)"
              :uniqueProductSizes="getUniqueProductSizes(feature.products, feature.title)"
              :hideFeature="filterFeaturesBasedOnSize(sizeRestrictions, getUniqueProductSizes(feature.products, feature.title))"
              :allowSizeRestrictionChange="stepInfo.show_addon_products ? false : true"
              data-aos="fade-right"
              :highlightedFeatures="child.highlighted_features ? child.highlighted_features.map(obj => obj.id) : null"
            />
            <div class="c-step__button-wrapper" v-if="child.link_text">
              <v-button variation="primary" :text="child.link_text" @click="saveHistory(stepId, child.link_url);"/>
            </div>
          </div>
          <div v-else class="c-step__child-step--singleselect">
            <div v-if="child.title">
              <v-textmedia
                :heading="child.title"
                :content="child.text"
                :number="index + 1"
                :images="child.media && child.media.length ? Object.values(child.media) : []"
                imgPosition="right"
                data-aos="fade-right"
              />
            </div>
            <div v-else>
              <v-choosable-feature v-if="child.features.length === 1"
                                   class="c-alternatives__main-feature"
                                   :title="child.features[0].title"
                                   :content="child.features[0].text"
                                   :number="index + 1"
                                   :images="child.features[0].media && child.features[0].media.length ? Object.values(child.features[0].media) : []"
                                   :uniqueProductSizes="getUniqueProductSizes(feature.products, feature.title)"
                                   :allowSizeRestrictionChange="stepInfo.show_addon_products ? false : true"
                                   data-aos="fade-right"
                                   :highlightedFeatures="child.highlighted_features ? child.highlighted_features.map(obj => obj.id) : null"
              />
            </div>
            <div class="c-step__basics c-step__basics--alternatives"
                 v-if="child.highlighted_features && child.highlighted_features.length">
              <v-basic-feature v-for="(feature, index) in child.highlighted_features"
                               :key="feature"
                               :images="feature.media && feature.media.length ? Object.values(feature.media) : []"
                               :heading="feature.title"
                               :content="feature.text"
                               :uniqueProductSizes="getUniqueProductSizes(feature.products, feature.title)"
                               data-aos="fade-up"
                               :data-aos-delay="(index+1)*100"
              />
            </div>
            <h4 class="c-feature-heading" v-if="child.features && child.features.length && child.features.length > 1">
              {{ translations[locale].text.you_also_get }}
            </h4>
            <div class="c-step__basics c-step__basics--alternatives" v-if="child.features && child.features.length > 1">
              <v-basic-feature v-for="(feature,index) in child.features"
                               :key="feature"
                               :images="feature.media && feature.media.length ? Object.values(feature.media) : []"
                               :heading="feature.title"
                               :content="feature.text"
                               :uniqueProductSizes="getUniqueProductSizes(feature.products)"
                               data-aos="fade-up"
                               :data-aos-delay="(index+1)*100"
              />
            </div>
            <div class="c-step__button-wrapper" v-if="child.link_text">
              <v-button v-if="stepInfo.is_design_step === 1" variation="primary" :text="child.link_text" href="#"
                        @click="saveHistory(stepId, child.link_url.replace('dynamic-category', slug)); child.design_features.length || child.features.length || child.highlighted_features.length ? saveFeatures(child, stepInfo.is_design_step) : ''; child.sizes ? saveSizes(child.sizes) : ''"/>
              <v-button v-else variation="primary" :text="child.link_text" href="#"
                        @click="saveHistory(stepId, child.link_url); child.features.length || child.highlighted_features.length ? saveFeatures(child) : ''; child.sizes ? saveSizes(child.sizes) : ''"/>
            </div>
          </div>
          <div class="c-step__button-wrapper" v-if="stepInfo.link_text && !stepInfo.show_assortment">
            <v-button v-if="stepInfo.show_addon_products" variation="primary" :text="stepInfo.link_text" href="#"
                      @click="saveHistory(stepId, stepInfo.link_url);"/>
            <v-button v-else variation="primary" :text="stepInfo.link_text" href="#"
                      @click="saveHistory(stepId, stepInfo.link_url); child.features.length || child.highlighted_features.length ? saveFeatures(child) : ''"/>
          </div>
        </div>
      </div>
      <!-- Step Content End -->

      <!-- Step Features -->
      <div class="c-features" v-if="stepInfo.is_multiselect && !stepInfo.show_result">
        <div class="c-features__section" v-for="(child) in sortedChildren" :key="child">
          <div v-if="child.features.length">
            <div class="c-step__head">
              <div>
                <h1 class="c-step__heading u-h2">
                  {{ child.title }}
                </h1>
                <div class="c-step__description" v-if="child.text" v-html="child.text"></div>
                <!-- <p class="c-step__description c-step__description--hint">
                    <v-icon size="16" type="info" placement="before" />
                    <span>Hint: you can only select one.</span>
                </p> -->
              </div>
            </div>
            <div class="c-step__basics" v-if="child.features && child.features.length && !child.is_multiselect">
              <v-basic-feature
                v-for="(basicFeature, index) in child.features.filter(obj => { if (obj.text){return obj}})"
                :key="basicFeature"
                :images="basicFeature.media && basicFeature.media.length ? Object.values(basicFeature.media) : []"
                :heading="basicFeature.title"
                :content="basicFeature.text"
                :hideFeature="basicFeature.title.en === '45 cm' || basicFeature.title.en === '60cm' ? true : false"
                :uniqueProductSizes="getUniqueProductSizes(basicFeature.products, basicFeature.title)"
                data-aos="fade-up"
                :data-aos-delay="(index+1)*100"
              />
            </div>
            <div v-if="child.features && child.features.length && !child.is_multiselect && !stepInfo.link_url">
              <div class="c-step__button-wrapper" v-if="child.link_text">
                <v-button variation="primary" :href="'/'+[locale]+child.link_url" :text="child.link_text"
                          v-on:click.prevent="saveHistory(stepId, child.link_url); child.features ? saveFeatures(child) : '' ; child.sizes ? saveSizes(child.sizes) : ''"/>
              </div>
            </div>
            <div class="c-features__add" v-if="child.features && child.is_multiselect">
              <div class="c-step__basics" v-if="child.highlighted_features && child.highlighted_features.length">
                <v-basic-feature
                  v-for="(basicFeature, index) in child.highlighted_features.filter(obj => { if (obj.text){return obj}})"
                  :key="basicFeature"
                  :images="basicFeature.media && basicFeature.media.length ? Object.values(basicFeature.media) : []"
                  :heading="basicFeature.title"
                  :content="basicFeature.text"
                  :hideFeature="basicFeature.title.en === '45 cm' || basicFeature.title.en === '60cm' ? true : false"
                  :uniqueProductSizes="getUniqueProductSizes(basicFeature.products, basicFeature.title)"
                  data-aos="fade-up"
                  :data-aos-delay="(index+1)*100"
                />
              </div>
              <v-choosable-feature
                v-for="(feature, index) in child.features"
                :key="feature"
                :featureId="feature.id"
                imgPosition="right"
                :title="feature.title"
                :checkboxLabel="feature.checkbox_title ? feature.checkbox_title : 'Translation missing'"
                :content="feature.text"
                :number="index + 1"
                :images="feature.media && feature.media.length ? Object.values(feature.media) : []"
                :checked="chosenFeatures.includes(feature.id)"
                :uniqueProductSizes="getUniqueProductSizes(feature.products, feature.title)"
                :allowSizeRestrictionChange="stepInfo.show_addon_products ? false : true"
                data-aos="fade-right"
                :data-order="feature.ordinal"
                :highlightedFeatures="child.highlighted_features ? child.highlighted_features.map(obj => obj.id) : null"
              />
              <div v-if="!stepInfo.link_url">
                <div class="c-step__button-wrapper" v-if="child.link_text">
                  <v-button variation="primary" :text="child.link_text"
                            @click="saveHistory(stepId, child.link_url); child.sizes ? saveSizes(child.sizes) : ''"/>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="child">
              <v-choosable-feature
                class="c-alternatives__main-feature"
                :title="child.title"
                :content="child.text"
                :images="child.media && child.media.length ? Object.values(child.media) : []"
                :allowSizeRestrictionChange="stepInfo.show_addon_products ? false : true"
                data-aos="fade-right"
                :highlightedFeatures="child.highlighted_features ? child.highlighted_features.map(obj => obj.id) : null"
              />
              <div class="c-step__button-wrapper" v-if="child.link_text">
                <v-button variation="primary" :text="child.link_text"
                          @click="saveHistory(child.id, child.link_url); child.features.length || child.highlighted_features.length ? saveFeatures(child) : '' "/>
              </div>
            </div>
          </div>
        </div>
        <div class="c-step__button-wrapper" v-if="stepInfo.link_text">
          <v-button variation="primary" :text="stepInfo.link_text" @click="saveHistory(stepId, stepInfo.link_url) "/>
        </div>
      </div>
      <!-- Step Features end -->

      <!-- Step -> Assortment -->
      <v-assortment v-if="stepInfo.show_assortment"
                    :assortmentProducts="products"
                    :alternativeProductsAssortment="alternativeProducts"
      />
      <!-- Step -> Assortment end -->

      <!-- Result page - Show added products + its features -->
      <div class="c-result" v-if="stepInfo.show_result && addedProduct">
        <div v-for="resultProduct in addedProduct"
             :key="resultProduct">
          <v-product
            class="c-result__product"
            :title="resultProduct.title"
            :description="resultProduct.text"
            :size="resultProduct.size"
            :sku="resultProduct.sku"
            :images="resultProduct.media && resultProduct.media.length ? Object.values(resultProduct.media) : []"
            :full="resultProduct.length === 1 ? 'true' : 'false'"
            :sizeOnImage="true"
            data-aos="fade-right"
          />
          <!-- <h2 v-if="resultProduct.features.length > 1" class="c-result__feature-description u-h2">
              {{translations[locale].text.about_your_new}} {{categoryinfo.title[locale]}}
          </h2>
          <div class="c-result__product-features" v-if="resultProduct.features.length > 1">
              <v-choosable-feature v-for="(feature, index) in resultProduct.features"
                  :key="feature"
                  class="c-alternatives__main-feature"
                  :title="feature.title[locale]"
                  :content="feature.text[locale]"
                  :number="index + 1"
                  :images="feature.media && feature.media.length ? Object.values(feature.media) : []"
                  :hideFeature="feature.title.en === '45 cm' || feature.title.en === '60cm' || feature.title.en === 'Worktop' || feature.title.en === 'Ceiling Ventilation' || feature.title.en === 'Wall mounted box design' || feature.title.en === 'Telescopic' || feature.title.en === 'Wall mounted glass design' || feature.title.en === 'Built-in'  ? true : false"
                  :uniqueProductSizes="getUniqueProductSizes(feature.products, feature.title[locale])"
                  :allowSizeRestrictionChange="stepInfo.show_addon_products ? false : true"
                  data-aos="fade-right"
              />
          </div> -->
        </div>

        <!-- <div class="c-result__product-bundle" :lang="locale" v-else>
             <div class="c-result__product-bundle__products">
                 <v-child-product :id="addedProduct[0].id"
                     :addToKitchenButton="false"
                     :title="addedProduct[0].title[locale]"
                     :description="addedProduct[0].text[locale]"
                     :size="addedProduct[0].size"
                     :sku="addedProduct[0].sku"
                     :images="addedProduct[0].media && addedProduct[0].media.length ? Object.values(addedProduct[0].media) : []"
                     :sizeOnImage="true"
                     :product="addedProduct[0]"
                 />
                 <v-icon class="c-result__product-bundle-plus" type="plus" size="40" />
                 <v-child-product :id="addedProduct[1].id"
                     :title="addedProduct[1].title[locale]"
                     :description="addedProduct[1].text[locale]"
                     :size="addedProduct[1].size"
                     :sku="addedProduct[1].sku"
                     :images="addedProduct[1].media && addedProduct[1].media.length ? Object.values(addedProduct[1].media) : []"
                     :addToKitchenButton="false"
                     :sizeOnImage="true"
                     :product="addedProduct[1]"
                 />
             </div>
         </div> -->
        <div class="c-step__result-features" v-for="p in addedProduct" :key="p">
          <h3 v-if="p.features">
            {{ p.title }} {{ translations[locale].text.features }}
          </h3>
          <div v-for="(feature,index) in p.features" :key="feature">
            <v-choosable-feature v-if="!feature.visibilityonproduct"
                                 class="c-alternatives__main-feature"
                                 :title="feature.title"
                                 :content="feature.text"
                                 :number="index + 1"
                                 :images="feature.media && feature.media.length ? Object.values(feature.media) : []"
                                 data-aos="fade-right"
                                 :highlightedFeatures='null'
            />
          </div>
        </div>

        <div class="c-step__button-wrapper" v-if="translations[locale].text.add_to_my_kitchen">
          <v-button variation="primary" :text="stepInfo.link_text"
                    @click="saveHistory(stepInfo.id, stepInfo.link_url.replace('dynamic-category', slug)); clearAddedProductObject();"/>
        </div>

        <!--Result step child steps -->
        <div v-if="stepInfo.child_steps">
          <div v-for="childStep in stepInfo.child_steps" :key="childStep">
            <v-textmedia
              :heading="childStep.title"
              :content="childStep.text"
              :number="index + 1"
              :images="childStep.media && childStep.media.length ? Object.values(childStep.media) : []"
              imgPosition="right"
              data-aos="fade-right"
            />
            <div class="c-step__button-wrapper" v-if="childStep.link_text">
              <v-button variation="primary" :text="childStep.link_text"
                        @click="saveHistory(childStep.id, childStep.link_url.replace('dynamic-category', slug)); clearAddedProductObject();"/>
            </div>
          </div>
        </div>
      </div>
      <v-stepbuttons/>
      <div class="lds-roller-wrapper" v-if="loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Step.js"></script>
<style lang="scss" src="./Step.scss" scoped></style>
