
import App from '@/App/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/scss/global.scss'
import { createApp, h } from 'vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp({
  created () {
    AOS.init()
  },
  render: () => h(App)
})

app.use(router)
app.use(store)
app.mount('#app')
