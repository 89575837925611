import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    lastVisit: new Date().getTime(),
    language: { en: 'English' },
    languages: {
      en: 'English',
      se: 'Svenska',
      dk: 'Dansk',
      no: 'Norsk',
      fi: 'Suomi'
    },
    locale: localStorage.getItem('locale') || 'en',
    pageInfo: [],
    categoryinfo: [],
    kitchenInfo: null,
    categorieslist: [],
    categoryId: null,
    categorySlug: null,
    stepId: null,
    stepInfo: null,
    chosenFeaturesObject: [],
    chosenFeatures: [],
    chosenDesignFeatures: [],
    temporaryChosenFeatures: [],
    addOnFeatures: [],
    stepHistory: {},
    doneCategories: [],
    addedProduct: [],
    addedProducts: {},
    kitchenId: null,
    kitchenSlug: null,
    stepCounter: 1,
    activeCat: 0,
    apiUrl: process.env.VUE_APP_APIURL,
    appBaseUrl: process.env.VUE_APP_BASEURL,
    token: process.env.VUE_APP_APITOKEN,
    env: process.env.NODE_ENV,
    tinyurlApi: process.env.VUE_APP_TINYURL_TOKEN,
    returnUrl: null,
    cookieConsent: false,
    showCookieConsent: false
  },
  getters,
  mutations,
  actions,
  plugins: [createPersistedState()]
})

export default store
